<template>
  <div class="as-guest">
    <h1 class="as-guest-title"> Continue as Guest</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap>
        <v-flex xs12>
          <v-text-field
            v-model="phone"
            ref="phone"
            :label="$t('register.phone')"
            :rules="phoneRules"
            :outlined="isMobile"
            prefix="+62"
            required
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-combobox
            v-model="deliveryMethod"
            :items="['SMS', 'WHATSAPP']"
            label="Delivery Method"
            :outlined="isMobile"
            :rules="deliveryMethodRules"
          ></v-combobox>
        </v-flex>
        <v-flex xs12 class="mb-9">
          <v-btn
            class="mt-5"
            tile
            :color="bgColor"
            dark
            width="100%"
            height="44px"
            @click="sendOtp()"
          >
            <span class="btn-text"> SEND </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <v-dialog
      v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="true"
    >
      <OtpAsGuest
        :phone="phone"
        :type="deliveryMethod"
         @verified="success()"
        @close="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/axios";
import OtpAsGuest from "@/components/OtpAsGuest";

export default {
  name: "LoginAsGuest",
  components: { OtpAsGuest },
  computed: {
    ...mapState(["isMobile", "bgColor", "isLogin"]),
  },
  data() {
    return {
      dialog: false,
      phone: "",
      deliveryMethod: "",
      valid: true,
      phoneRules: [
        (v) => !!v || this.$t("register.form.error-phone"),
        (v) =>
          /^8\d{7,15}$/.test(v) || this.$t("register.form.error-phone-format"),
      ],
      deliveryMethodRules: [(v) => !!v || "Delivery Method is required"],
    };
  },
  methods: {
    success() {
      this.dialog = false;
      this.$router.push({ name: 'Home'})
    },
    sendOtp() {
      if (this.$refs.form.validate()) {
        this.$store.commit("setIsLoading", true);

        let data = {
          type: this.deliveryMethod,
          phone: "0" + this.phone,
        };

        axios
          .post("/api/v1/member/login_otp_send", data)
          .then((response) => {
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setText", response.data.message);

            if (response.status) {
              this.dialog = true;
            }
          })
          .catch((error) => {
            this.errorHandling(error);
          })
          .finally(() => {
            this.$store.commit("setIsLoading", false);
          });
      }
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$router.push({ name: 'Home'})
    }
    this.setMetaInfo(null, this.$route.name);
    this.$store.commit("setIsHome", false);
    this.$store.commit("setIsMemberPage", false);
    this.$store.commit("setIsLoginPage", true);
    this.$store.commit("setActivePage", "Continue as Guest");
  },
};
</script>

<style scoped>
.as-guest {
  color: #ffffff;
  position: relative;
  margin: auto;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 90%;
}

.as-guest-title {
  font-size: 1.7rem;
  color: #000000;
  font-weight: 600;
  margin-bottom: 20px;
}

.btn-text {
  font-weight: 500;
}
</style>
